import React from 'react';

import CustomerFeedback from '../Home/patterns/CustomerFeedback/CustomerFeedback';
import Hero from '../../components/Hero/Hero';
import FAQ from '../../components/FAQ/FAQ';
import ProductCard from '../../components/ProductCard/ProductCard';
import ReasonsBelongingPurple from '../../components/ReasonsBelongingPurple/ReasonsBelongingPurple';
import NuDisclaimer from '../../components/NuDisclaimer/NuDisclaimer';
import ValueProposal from '../../components/ValueProposal/ValueProposal';

import ComponentDefaultProps from './variant/MultiProductHomeProps';
import HomePropType from './variant/HomePropTypes';
import Perks from './patterns/Perks/Perks';
import PressSection from './patterns/Press/Press';
import Blog from './patterns/Blog/Blog';

const {
  hero,
  disclaimer,
  savingsProduct,
  savingsPerks,
  creditsProduct,
  creditsPerks,
  perks,
  reasons,
  press,
  blog,
  faq,
  feedbacks,
} = ComponentDefaultProps;

const MultiProductHomePage = () => (
  <>
    <Hero {...hero} />
    <NuDisclaimer {...disclaimer} />
    <ProductCard {...savingsProduct} />
    <ValueProposal {...savingsPerks} />
    <ProductCard {...creditsProduct} />
    <ValueProposal {...creditsPerks} />
    <Perks {...perks} />
    <ReasonsBelongingPurple {...reasons} />
    <CustomerFeedback feedbacks={feedbacks} />
    <PressSection {...press} />
    <Blog {...blog} />
    <FAQ {...faq} />
  </>
);

MultiProductHomePage.propTypes = HomePropType;

export default MultiProductHomePage;
